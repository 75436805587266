import { useState } from "react";
import {
  Typography,
  Box,
  Divider,
  Chip,
  Grid,
  useTheme,
  useMediaQuery,
  Snackbar,
  Alert,
} from "@mui/material";
import { customTheme } from "../theme";
import { Text } from "../elements/text";
import { ReactComponent as ArrowDownIcon } from "../assets/icons/arrow-down.svg";
import { CustomButton } from "../elements/button";
import { OptionWithType } from "../types";
import { ChevronRight, ExpandLess, AddSharp } from "@mui/icons-material";
import { Badge } from "../elements/badge";
import { UPDATE_ORDER_STATUS } from "../graphql/mutations";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Loader } from "../elements/loader";
import { getOrderStatusConfig } from "../constants/constants";
import parsePhoneNumber from "libphonenumber-js";
import { extractAllErrorMesasges } from "../utils/graphql";
import { ConfirmationDailog } from "../elements/confirmation-dialog";
import { OrderedItem, OrderStatus, ServiceMethod, VendorOrder } from "../types/order";
import { useMutationHook } from "../hooks/use-mutation.hook";

interface Props {
  order: VendorOrder;
  isCollapsed?: boolean;
  refetchOrders: any;
  // eslint-disable-next-line no-unused-vars
}

export function OrderCard({
  order,
  refetchOrders,
  isCollapsed = false,
}: Props) {
  const [showOrderItems, setShowOrderItems] = useState<boolean>(false);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const [collapseCard, setCollapseCard] = useState<boolean>(
    isCollapsed && isMobileView
  );
  const [showErrSnackbar, setShowErrSnackbar] = useState<boolean>(true);
  const [openModel, setOpenModel] = useState(false);

  const { user } = useAuthenticator();
  const token = user.getSignInUserSession()?.getAccessToken()?.getJwtToken();
  const orderStatusConfig = getOrderStatusConfig(order.serviceMethod);

  const {
    mutate: updateResource,
    loading: updateLoading,
    error: updateError,
  } = useMutationHook({
    mutation: UPDATE_ORDER_STATUS,
    onCompleteHandler: () => {
      refetchOrders();
    },
    onError: () => {
      setShowErrSnackbar(true);
    },
  });

  const location = order.patronOrder?.location;
  const serviceMethod = order.serviceMethod

  const handleCollapseCard = () => {
    if (isMobileView) setCollapseCard(!collapseCard);
  };

  const handleCollapseItems = () => {
    setShowOrderItems(!showOrderItems);
  };

  const onClickHandler = () => {
    if (orderStatusConfig[order.orderStatus].confirmCheck) {
      setOpenModel(true);
    } else {
      updateOrderStatus(orderStatusConfig[order.orderStatus].nextOrderStatus)
    }
  };

  const updateOrderStatus = (status: OrderStatus) => {
    updateResource({
      variables: {
        id: order._id,
        status: status,
      },
    });
  };

  const handleSnackbarClose = () => {
    setShowErrSnackbar(false);
  };

  const errors = extractAllErrorMesasges(updateError);

  const PatronLocation = () => {
    switch (serviceMethod) {
      case ServiceMethod.DELIVERY:
        return <Text
          text={`Section: ${location?.sectionName}, Row ${location?.row}, Seat ${location?.seat}`}
          fontSize={14}
          fontWeight={600}
        />

      case ServiceMethod.COLLECTION_POINT:
        return <Text
          text={`Section: ${location?.sectionName}, Collection Point: ${location?.collectionPointName}`}
          fontSize={14}
          fontWeight={600}
        />

      default:
        return <></>
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        m: 2,
      }}
    >
      <ConfirmationDailog
        title={`Do you want to mark the order as delivered ? This action cannot be undone.`}
        open={openModel}
        handleCancel={() => setOpenModel(false)}
        handleContinue={() => {
          setOpenModel(false);
          updateOrderStatus(OrderStatus.COMPLETE);
        }}
      />
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
        onClick={handleCollapseCard}
      >
        <Text
          text={`Order # ${order?.orderNumber}`}
          fontSize={16}
          fontWeight={600}
        />
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Chip
            label={orderStatusConfig[order.orderStatus].text}
            size="small"
            icon={
              <Badge
                mr={2}
                backgroundColor={
                  orderStatusConfig[order.orderStatus].badgeColor
                }
              ></Badge>
            }
            style={{
              backgroundColor:
                orderStatusConfig[order.orderStatus].backgroundColor,
              color: orderStatusConfig[order.orderStatus].textColor,
              paddingLeft: "4px",
            }}
          />
          {isMobileView &&
            (collapseCard ? (
              <ChevronRight onClick={handleCollapseCard} />
            ) : (
              <ExpandLess onClick={handleCollapseCard} />
            ))}
        </Box>
      </Box>
      {!collapseCard && (
        <Box>
          <Divider sx={{ borderColor: "#D0D5DD", ml: 1, mr: 1 }} />
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Text text={"Order Details"} fontSize={14} fontWeight={200} />
            <Box display="flex" alignItems={"center"}>
              <Text
                text={`x${order.items
                  .filter((i) => !i.isRejected)
                  .length.toString()}`}
                fontSize={16}
                fontWeight={500}
              />
              {showOrderItems ? (
                <ExpandLess onClick={handleCollapseItems} />
              ) : (
                <ChevronRight onClick={handleCollapseItems} />
              )}
            </Box>
            {showOrderItems && (
              <Grid item xs={12}>
                {order?.items?.map((item: OrderedItem, index: number) => {
                  return (
                    !item.isRejected && (
                      <Box key={`${item._id}-${index}`}>
                        <Box px={2} display={"flex"}>
                          <Box mr={2}>
                            <Typography
                              fontFamily={customTheme.fontFamily}
                              fontSize={16}
                              fontWeight={700}
                              color={"#2A2A2A"}
                            >
                              {item?.updatedQuantity}
                            </Typography>
                          </Box>
                          <Typography
                            fontFamily={customTheme.fontFamily}
                            fontSize={16}
                            fontWeight={700}
                            color={"#2A2A2A"}
                          >
                            {item.name}
                          </Typography>
                        </Box>
                        {item?.customisations &&
                          item?.customisations.length > 0 && (
                            <Box
                              sx={{ display: "flex", alignItems: "center" }}
                              px={2}
                            >
                              <AddSharp sx={{ marginRight: 1, fontSize: 16 }} />
                              {item.customisations?.map(
                                (
                                  customisationOption: OptionWithType,
                                  index: number
                                ) => {
                                  return (
                                    <Typography
                                      key={`${customisationOption._id}-${index}`}
                                      fontSize={14}
                                    >
                                      {`${index == 0 ? "" : ", "}${customisationOption.name
                                        }`}
                                    </Typography>
                                  );
                                }
                              )}
                            </Box>
                          )}
                      </Box>
                    )
                  );
                })}
              </Grid>
            )}
          </Box>

          <Divider sx={{ borderColor: "#D0D5DD", ml: 1, mr: 1 }} />

          <Box sx={{ p: 2, justifyContent: "center" }}>
            <Text text={"Pickup From:"} fontSize={14} fontWeight={200} />
            <Box
              sx={{
                minHeight: 40,
                backgroundColor: "#F2F4F7",
                borderRadius: 2,
                display: "flex",
                alignItems: "center",
                paddingLeft: 1,
              }}
            >
              <Text
                text={`${order?.vendor?.tradingName}, ${order?.vendor?.stallID}`}
                fontSize={14}
                fontWeight={600}
              />
            </Box>
            <Box display="flex" justifyContent="center" my={1}>
              <ArrowDownIcon />
            </Box>
            <Text text={"Drop Off To:"} fontSize={14} fontWeight={200} />
            <Box
              sx={{
                minHeight: 80,
                backgroundColor: "#F2F4F7",
                borderRadius: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                paddingLeft: 1,
              }}
            >
              <Box
                display={"flex"}
                flexWrap={"wrap"}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Text
                  text={order.patron?.name}
                  fontSize={14}
                  fontWeight={600}
                />
                <Box
                  sx={{
                    p: 0.5,
                    m: 0.5,
                    mr: 2,
                    borderRadius: 1,
                    backgroundColor: "#344054",
                  }}
                >
                  <Text
                    text={parsePhoneNumber(order.patron?.phoneNumber || "")
                      ?.formatInternational()
                      .toString()}
                    fontSize={10}
                    fontWeight={200}
                    color={"#F2F2F2"}
                  />
                </Box>
              </Box>
              <PatronLocation />
            </Box>
          </Box>
          <Box p={2} display={"flex"}>
            <CustomButton
              loading={updateLoading}
              title={orderStatusConfig[order.orderStatus].buttonText}
              style={{ borderRadius: 8 }}
              onClick={onClickHandler}
            />
          </Box>
        </Box>
      )}
      <Box>
        <Snackbar
          open={showErrSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Box>
            {errors.map((err, index) => {
              return (
                <Box key={err + index} mb={2}>
                  <Alert variant="filled" severity="error">
                    {err}
                  </Alert>
                </Box>
              );
            })}
          </Box>
        </Snackbar>
      </Box>
    </Box>
  );
}
