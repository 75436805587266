import React from "react";

interface Props {
  src: string;
  style?: React.CSSProperties;
}

export const Image = (props: Props) => {
  return (
    <img
      src={props.src}
      style={{
        width: "100%",
        height: "auto",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        ...props.style,
      }}
    />
  );
};
