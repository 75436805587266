/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import { ApolloError, DocumentNode, useLazyQuery } from "@apollo/client";
import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { isTokenExpired } from "../utils/refresh-token";

interface Props {
  query: DocumentNode;
  variables?: object;
  skip?: boolean;
  onCompleteHandler?: (data: any) => void;
  onError?: (e: any) => void;
  skipInitialCall?: boolean;
}

interface UseQueryHookResponse {
  fetch?: any;
  data: any;
  loading: boolean;
  client?: any;
  error?: ApolloError;
}

export const useQueryHook = ({
  query,
  variables,
  skip,
  onCompleteHandler = () => {},
  onError = () => {},
  skipInitialCall = false,
}: Props): UseQueryHookResponse => {
  const [fetch, { data, loading, error, client }] = useLazyQuery(query, {
    onError: (e: any) => {
      onError(e);
    },
    onCompleted: (data) => {
      onCompleteHandler(data);
    },
  });

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.accessToken?.jwtToken;
    if (!token) {
      return;
    }
    if (!skipInitialCall) {
      makeFetchQuery(variables);
    }
  };

  const makeFetchQuery = async (variables: any) => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user?.signInUserSession?.accessToken?.jwtToken;
    if (isTokenExpired(token)) {
      return makeFetchQueryWithRefreshToken(variables);
    }
    return fetch({
      nextFetchPolicy: "network-only",
      fetchPolicy: "network-only",
      variables,
      context: {
        headers: {
          authorization: token,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
    });
  };

  const makeFetchQueryWithRefreshToken = async (variables: any) => {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    const newToken = user?.signInUserSession?.accessToken?.jwtToken;
    return fetch({
      nextFetchPolicy: "network-only",
      fetchPolicy: "network-only",
      variables,
      context: {
        headers: {
          authorization: newToken,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
    });
  };

  return {
    data,
    loading,
    fetch: async (variables: any) => {
      return makeFetchQuery(variables);
    },
    error,
    client,
  };
};
