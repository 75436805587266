import { OrderStatus } from "./order";

/* eslint-disable no-unused-vars */
export type Runner = {
  _id?: string;
  name: string;
  email: string;
  phoneNumber: string;
  runnerNumber: string;
  address: string;
  tfn: string;
  bankAccount: string;
  superAnnuationFund: string;
  venueID: string;
  sectionIDs: string[];
  cognitoID: string;
};

export type Location = {
  venue: string;
  section: string;
  venueName: string;
  sectionName: string;
  collectionPoint: string;
  collectionPointName: string;
  row: string;
  seat: string;
};

export type Section = {
  label: string;
  id: string;
};

export type patron = {
  name: string;
  phoneNumber: string;
};

export type Venue = {
  _id: string;
  name: string;
  address: string;
  venueMapImageUrl: string;
  sections: Section[];
};

export type patronOrder = {
  location: Location;
  venue: Venue;
};

export type Vendor = {
  tradingName: string;
  stallID: string;
  sectionIDs: string[];
  venueID: string;
};

export type OrderStatusConfig = {
  [key: string]: {
    text: string;
    buttonText: string;
    nextOrderStatus: OrderStatus,
    backgroundColor: string;
    textColor: string;
    badgeColor: string;
    order: number;
    confirmCheck?: boolean;
  };
};

export enum UserType {
  // eslint-disable-next-line no-unused-vars
  runner = "runner",
}

export type OrderItem = {
  name: string;
  _id: string;
};

export type OptionWithType = {
  _id: string;
  name: string;
};
