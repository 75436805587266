const errorCodes = {
  INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR",
};

export const extractAllErrorMesasges = (error: any): string[] => {
  const result: string[] = [];

  error?.graphQLErrors.map((e: any) => {
    const code = e?.extensions?.code;
    const exception = e?.extensions?.exception;
    if (code === errorCodes.INTERNAL_SERVER_ERROR) {
      result.push(exception?.message?.message || exception?.message);
    }
  });

  if (error?.networkError) {
    result.push("Error processing request. Please contact Admin.");
  }

  return result;
};
