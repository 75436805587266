import { getOrderStatusConfig } from "../constants/constants";
import { VendorOrder } from "../types/order";

const compareLatestOrders = (a: VendorOrder, b: VendorOrder) => {
  if (a.updatedAt! < b.updatedAt!) {
    return 1;
  }
  if (a.updatedAt! > b.updatedAt!) {
    return -1;
  }
  return 0;
};

export const sortOrders = (orders: VendorOrder[]): VendorOrder[] => {
  return orders?.slice().sort((a: VendorOrder, b: VendorOrder) => {

    const firstOrderStatus = getOrderStatusConfig(a.serviceMethod)[a.orderStatus].order;
    const secondOrderStatus = getOrderStatusConfig(b.serviceMethod)[b.orderStatus].order;

    if (firstOrderStatus === secondOrderStatus) {
      return compareLatestOrders(a, b);
    }
    if (firstOrderStatus < secondOrderStatus) {
      return 1;
    }
    if (firstOrderStatus > secondOrderStatus) {
      return -1;
    }
    return 0;
  });
};
