import React from "react";
import { Box, Button, CircularProgress, FormControl } from "@mui/material";
import { Text } from "./text";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
  title: string;
  type?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
  loading?: boolean;
}

export const CustomButton = ({
  onClick = () => {},
  title,
  style = {},
  loading = false,
}: Props) => {
  return (
    <FormControl fullWidth>
      <Box
        sx={{
          background: "black",
          width: "100%",

          borderRadius: "30px",
          WebkitBorderRadius: "30px",
          p: "6px",
          "&:hover": {
            background: "black",
          },
          ...style,
        }}
      >
        <Button
          disabled={loading}
          variant="outlined"
          type="submit"
          sx={{
            background: "black",
            width: "100%",
            height: "40px",
            borderRadius: "30px",
            borderColor: "#D2FF28",
            WebkitBorderRadius: "30px",
            color: "#D2FF28",
            fontWeight: "700",
            fontSize: "18px",
            textTransform: "none",
            borderWidth: "2px",
            "&:hover": {
              background: "black",
              borderColor: "#D2FF28",
            },
          }}
          onClick={onClick}
        >
          {!loading ? (
            title
          ) : (
            <CircularProgress sx={{ color: "white" }} size={30} />
          )}
        </Button>
      </Box>
    </FormControl>
  );
};
