import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Box,
  MenuItem,
  Menu,
  Typography,
  Divider,
  Snackbar,
  Alert,
  Chip,
} from "@mui/material";
import { Header } from "../components/header";
import { Text } from "../elements/text";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import { ReactComponent as UserIcon } from "../assets/icons/user.svg";
import { OrderCard } from "../components/order-card";
import { useAuthenticator } from "@aws-amplify/ui-react";
import MapModalIcon from "../components/map-modal";
import { AppContext } from "../context/app-context";
import { GET_ORDERS, GET_VENUE } from "../graphql/query";
import { UserType } from "../types";
import { sortOrders } from "../utils/utils";
import { Loader } from "../elements/loader";
import { CLEAR_DEVICE } from "../graphql/mutations";
import { extractAllErrorMesasges } from "../utils/graphql";
import { Notification } from "../elements/notification";
import { NotificationContext } from "../context/notification-context";
import { OrderStatus } from "../types/order";
import { GridOn } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useQueryHook } from "../hooks/use-query.hook";
import { useMutationHook } from "../hooks/use-mutation.hook";
import { RUNNER_HELP_PAGE_ROUTE } from "../constants/routes";
import { AdminLoginContext } from "../context/admin-login-context";
import { customTheme } from "../theme";

export const Home = () => {
  const { signOut } = useAuthenticator();
  const notificationContext = useContext(NotificationContext);
  const loginContext = useContext(AdminLoginContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { runner } = useContext(AppContext);
  const { user } = useAuthenticator();
  const token = user.getSignInUserSession()?.getAccessToken()?.getJwtToken();

  const [showErrSnackbar, setShowErrSnackbar] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const { mutate: updateResource } = useMutationHook({
    mutation: CLEAR_DEVICE,
    onError: (err) => {
      setShowErrSnackbar(true);
      setErrorMessages(extractAllErrorMesasges(err));
    },
  });

  const runnerID = runner?.value?._id;
  const venueID = runner?.value?.venueID;

  const {
    data: runnerOrderResponse = { getRunnerOrders: { orders: [], items: [] } },
    loading: ordersLoading,
    fetch: fetchOrders,
  } = useQueryHook({
    query: GET_ORDERS,
    variables: {
      runnerID: runnerID,
      orderFilterOptions: {
        orderStatus: [OrderStatus.ASSIGNED, OrderStatus.PICKED, OrderStatus.AT_COLLECTION_POINT],
      },
    },
    skip: !runnerID,
    onCompleteHandler: () => {
      setPageLoading(false);
    },
  });

  const { data: venueData = { getVenue: [] }, loading: venueLoading } =
    useQueryHook({
      query: GET_VENUE,
      variables: {
        id: venueID,
      },
      onCompleteHandler: () => {
        setPageLoading(false);
      },
    });

  useEffect(() => {
    notificationContext.addSubcribers({
      id: "runner-order-dashaboard-refetch-orders",
      call: () => {
        fetchOrders({
          runnerID: runnerID,
          orderFilterOptions: {
            orderStatus: [OrderStatus.ASSIGNED, OrderStatus.PICKED, OrderStatus.AT_COLLECTION_POINT],
          },
        });
      },
    });

    return () => {
      notificationContext.callSubscribers();
    };
  }, []);

  const navigate = useNavigate();

  const signOutHandler = async () => {
    clearDevice();
    signOut();
    if (loginContext.isAdminLogin?.value) {
      loginContext.clear();
    }
  };

  const clearDevice = () => {
    if (runnerID) {
      updateResource({
        variables: {
          input: {
            id: runnerID,
            userType: UserType.runner,
          },
        },
      });
    }
  };
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (ordersLoading || pageLoading) {
    return <Loader visible={true} />;
  }
  const { data: orders = [] } = runnerOrderResponse.getRunnerOrders;

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Header
            leftComponent={
              <Box display={"flex"} flexDirection={"column"}>
                <Box>
                  <Text
                    text={`Hi ${runner?.value?.name} 👋🏽`}
                    fontSize={18}
                    fontWeight={600}
                  />
                  <Text
                    text={`You are Runner Number #${runner?.value?.runnerNumber}`}
                  />
                  <Text text={`Total orders ${orders?.length}`} />
                </Box>
                {loginContext.isAdminLogin?.value && (
                  <Box>
                    <Chip
                      component="span"
                      label="Admin Session"
                      sx={{
                        flexGrow: 1,
                        fontFamily: customTheme.fontFamily,
                        color: "white",
                        fontWeight: "600",
                        fontSize: "14px",
                        backgroundColor: "#EF5455",
                        borderRadius: "6px",
                        height: "30px",
                      }}
                    ></Chip>
                  </Box>
                )}
              </Box>
            }
            rightComponent={
              <Box
                display={"flex"}
                justifyContent={"space-around"}
                alignItems={"center"}
              >
                <Notification />
                <MapModalIcon
                  venue={venueData?.getVenue}
                  loading={venueLoading}
                />
                {/* commented to remove access but keep functionality for future */}
                {/* <GridOn onClick={() => navigate("/report")} /> */}
                <IconButton
                  sx={{ padding: "5px" }}
                  href={RUNNER_HELP_PAGE_ROUTE}
                  target="_blank"
                >
                  <HelpIcon />
                </IconButton>
                <IconButton
                  sx={{ padding: "5px" }}
                  onClick={handleMenu}
                  color="inherit"
                >
                  <UserIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={signOutHandler}>Sign out</MenuItem>
                </Menu>
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12}>
          {orders.length === 0 ? (
            <Grid item xs={12}>
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h6">No Orders Available</Typography>
              </Box>
            </Grid>
          ) : (
            <Grid container>
              {sortOrders(orders).map((order, index: number) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={3}
                    key={`${order._id}-${index}`}
                  >
                    <Box margin="auto">
                      <OrderCard
                        order={order}
                        refetchOrders={() => {
                          fetchOrders({
                            runnerID: runnerID,
                            orderFilterOptions: {
                              orderStatus: [
                                OrderStatus.ASSIGNED,
                                OrderStatus.PICKED,
                                OrderStatus.AT_COLLECTION_POINT,
                              ],
                            },
                          });
                        }}
                        isCollapsed={index > 0}
                      />
                      <Divider
                        sx={{
                          display: { xs: "block", sm: "block", md: "none" },
                          borderColor: "black",
                          ml: 2,
                          mr: 2,
                        }}
                      />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Box>
        <Snackbar
          open={showErrSnackbar}
          autoHideDuration={6000}
          onClose={() => {
            setShowErrSnackbar(false);
          }}
        >
          <Box>
            {errorMessages.map((err, index) => {
              return (
                <Box key={err + index} mb={2}>
                  <Alert variant="filled" severity="error">
                    {err}
                  </Alert>
                </Box>
              );
            })}
          </Box>
        </Snackbar>
      </Box>
    </>
  );
};
