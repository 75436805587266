import React, { useState } from "react";
import { createContext, ReactElement } from "react";

export interface ISubscriber {
  id: string;
  call: () => void;
}

type NotificationContextType = {
  clear: () => void;
  // eslint-disable-next-line no-unused-vars
  addSubcribers: (subscriber: ISubscriber) => void;
  getSubscribers: () => ISubscriber[];
  callSubscribers: () => void;
};

export const NotificationContext = createContext<NotificationContextType>({
  clear: () => {},
  callSubscribers: () => {},
  // eslint-disable-next-line no-unused-vars
  addSubcribers: (subscriber: ISubscriber) => {},
  getSubscribers: () => [],
});

interface Props {
  children: ReactElement;
}

function NotificationContextProvider({ children }: Props) {
  const [subscribers, setSubscribers] = useState<{
    [key: string]: ISubscriber;
  }>({});

  const addSubcribers = (subscriber: ISubscriber) => {
    if (!subscriber) {
      return;
    }
    console.log("subscribing...", subscriber.id);

    if (subscriber.id in subscribers) {
      return;
    }
    setSubscribers({
      ...subscribers,
      [subscriber.id]: subscriber,
    });
  };

  const clear = () => {
    console.log("clearing notifications");
    setSubscribers({});
  };

  const callSubscribers = () => {
    if (!subscribers) {
      return;
    }

    for (const key of Object.keys(subscribers || {})) {
      const subscriber = subscribers[key];
      if (subscriber && subscriber.call) {
        subscriber.call();
      }
    }
  };

  const value: NotificationContextType = {
    clear,
    addSubcribers,
    callSubscribers,
    getSubscribers: () => {
      if (!subscribers) {
        return [];
      }
      return Object.keys(subscribers).map((k) => subscribers[k]);
    },
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
}

export default NotificationContextProvider;
