import { CognitoUser } from "@aws-amplify/auth";
import { Box, Grid } from "@mui/material";
import { Auth } from "aws-amplify";
import { ReactElement, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  IS_ADMIN_LOGIN,
  ADMIN_LOGIN_EMAIL,
  ADMIN_LOGIN_RUNNER_ID,
} from "../constants/config";
import { Loader } from "../elements/loader";
import { Text } from "../elements/text";

interface Props {
  children: ReactElement;
}

export function AdminTokenAuthenticator({ children }: Props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const adminTokenLogin = async (
    email: string,
    token: string,
    runnerId: string
  ): Promise<CognitoUser | any> => {
    Auth.configure({ storage: sessionStorage });
    const session = await Auth.signIn(email, "");
    const result = await Auth.sendCustomChallengeAnswer(session, token);
    if (result?.signInUserSession?.accessToken?.jwtToken) {
      sessionStorage.setItem(IS_ADMIN_LOGIN, "y");
      sessionStorage.setItem(ADMIN_LOGIN_EMAIL, email);
      sessionStorage.setItem(ADMIN_LOGIN_RUNNER_ID, runnerId);
      const url = window.location.toString();
      window.location.replace(url.substring(0, url.indexOf("?")));
    } else {
      setError(
        "Sorry, unable to log in as admin. Please refresh your admin console and try again."
      );
      setLoading(false);
    }
  };

  const params = new URLSearchParams(useLocation().search);
  const token = params.get("t");
  const adminEmail = params.get("email");
  const asRunnerId = params.get("rid");

  if (token && adminEmail && asRunnerId && !(loading || error)) {
    setLoading(true);
    console.log("running...");
    adminTokenLogin(adminEmail, token, asRunnerId);
  }

  if (loading) {
    return (
      <Grid container>
        <Loader visible={loading} />
      </Grid>
    );
  } else if (error) {
    return (
      <Grid item xs={12}>
        <Box mt={2} mb={2} sx={{ textAlign: "center" }}>
          <Text color={"red"} text={error} fontSize={24} fontWeight={16} />
        </Box>
      </Grid>
    );
  } else {
    return children;
  }
}
