import React from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  Footer,
  Header,
  SignInFooter,
  SignInHeader,
} from "./amplify/amplify-custom-elements";
import awsconfig from "./aws-exports";
import { Wizard } from "./wizard";
import { Box } from "@mui/material";
import { customTheme } from "./theme";
import AppContextProvider from "./context/app-context";
import NotificationContextProvider from "./context/notification-context";
import { SnackbarProvider } from "notistack";
import { AdminTokenAuthenticator } from "./auth/admin-token-auth";
import { Amplify, Auth } from "aws-amplify";
import AdminLoginContextProvider from "./context/admin-login-context";

const components = {
  Header: () => <Header />,
  Footer: () => <Footer />,
  SignIn: {
    Header: () => <SignInHeader title="Runner Login" />,
    Footer: () => <SignInFooter />,
  },
};

Amplify.configure(awsconfig);
if (sessionStorage.getItem("admin.login") === "y") {
  Auth.configure({ storage: sessionStorage });
}

function App() {
  return (
    <AdminLoginContextProvider>
      <AdminTokenAuthenticator>
        <Authenticator
          hideSignUp={true}
          components={components}
          className="amplify"
        >
          <Box
            sx={{
              background: customTheme.background,
              minHeight: "100vh",
            }}
          >
            <AppContextProvider>
              <NotificationContextProvider>
                <SnackbarProvider maxSnack={3}>
                  <Wizard />
                </SnackbarProvider>
              </NotificationContextProvider>
            </AppContextProvider>
          </Box>
        </Authenticator>
      </AdminTokenAuthenticator>
    </AdminLoginContextProvider>
  );
}

export default App;
