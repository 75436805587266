import { useState } from "react";

export interface IContextValue<T> {
  value?: T;
  // eslint-disable-next-line no-unused-vars
  setValue: (value: T) => void;
}

export function useIContext<S>(defaultValue?: S): IContextValue<S> {
  const [value, setValue] = useState<S | undefined>(defaultValue);

  return { value, setValue };
}
