import { Box, styled } from "@mui/material";

interface BadgeProps {
  backgroundColor: string;
}

export const Badge = styled(Box)((props: BadgeProps) => ({
  width: "6px",
  height: "6px",
  borderRadius: "12px",
  backgroundColor: props.backgroundColor,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: 18,
}));
