export enum OptionGroupSelectionType {
  multiSelect = "multiSelect",
  singleSelect = "singleSelect",
}
export interface Location {
  venue: string;
  venueName: string;
  sectionName: string;
  section: string;
  collectionPoint: string;
  collectionPointName: string;
  seat: string;
  row: string;
}
export enum PatronOrderStatus {
  PAYMENT_PENDING = "PAYMENT_PENDING",
  RECEIVED = "RECEIVED",
  PREPARING = "PREPARING",
  PREPARED = "PREPARED",
  PARTIALLY_ASSIGNED = "PARTIALLY_ASSIGNED",
  ASSIGNED = "ASSIGNED",
  DELIVERED = "DELIVERED",
  REJECTED = "REJECTED",
}
export interface OrderPatron {
  _id: string;
  name: string;
  phoneNumber: string;
}

export interface OrderCharges {
  markUpRate: number;
  deliveryCharge: number;
  stripeFee: number;
}

export interface OrderVendor {
  _id: string;
  tradingName: string;
  phoneNumber: string;
  stallID: string;
}

export interface OrderPatron {
  _id: string;
  name: string;
  phoneNumber: string;
}

export interface OrderRunner {
  _id: string;
  name: string;
  phoneNumber: string;
}

export enum PaymentStatus {
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PAYMENT_AUTHORISED = "PAYMENT_AUTHORISED",
  PAYMENT_CAPTURED = "PAYMENT_CAPTURED",
  PAYMENT_CANCELLED = "PAYMENT_CANCELLED",
}

export enum ServiceMethod {
  DELIVERY = "DELIVERY",
  COLLECTION_POINT = "COLLECTION_POINT",
  COLLECT_FROM_VENDOR = "COLLECT_FROM_VENDOR",
}

export interface PatronOrder {
  _id: string;
  patron?: OrderPatron;
  orderStatus?: PatronOrderStatus;
  serviceMethod: ServiceMethod;
  location: Location;
  vendorOrders: VendorOrder[];
  pricing: Pricing;
  paymentIntentId: string;
  paymentIntentClientSecret: string;
  charges?: OrderCharges;
  paymentStatus: PaymentStatus;
  orderNumber: string;
  createdAt?: Date;
  updatedAt?: Date;
  isDeleted?: boolean;
}

export interface OptionWithType {
  _id: string;
  name: string;
  description: string;
  optionGroupID: string;
  type?: OptionGroupSelectionType;
  price?: number;
  markupPrice?: number;
}

export interface OrderedItem {
  _id: string;
  name: string;
  price: number;
  markupPrice: number;
  count: number;
  updatedQuantity: number;
  isRejected?: boolean;
  customisations?: OrderedItemOption[];
}

export interface OrderedItemOption {
  _id: string;
  name: string;
  price: number;
  markupPrice: number;
}

export interface VendorOrder {
  _id: string;
  orderStatus: OrderStatus;
  patronOrderID: string;
  items: OrderedItem[];
  isPaymentComplete: boolean;
  pricing?: Pricing;
  patronOrder?: PatronOrder;
  runner?: OrderRunner;
  patron?: OrderPatron;
  vendor?: OrderVendor;
  options?: OptionWithType[];
  orderNumber: string;
  createdAt?: Date;
  updatedAt?: Date;
  isOrderUpdated?: boolean;
  isDeleted?: boolean;
  serviceMethod: ServiceMethod;
}
export enum OrderStatus {
  RECEIVED = "RECEIVED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  PATRON_CANCELLED = "PATRON_CANCELLED",
  ADMIN_CANCELLED = "ADMIN_CANCELLED",
  PREPARING = "PREPARING",
  PREPARED = "PREPARED",
  PICKED = "PICKED",
  COMPLETE = "COMPLETE",
  ASSIGNED = "ASSIGNED",
  AT_COLLECTION_POINT = "AT_COLLECTION_POINT"
}
export interface OrderedItems {
  itemID: string;
  count: number;
  customisations: string[];
}

export interface Pricing {
  subTotal: number;
  deliveryCharge: number;
  total: number;
}
