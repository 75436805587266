import React from "react";
import { createContext, ReactElement } from "react";
import {
  ADMIN_LOGIN_EMAIL,
  ADMIN_LOGIN_RUNNER_ID,
  IS_ADMIN_LOGIN,
} from "../constants/config";
import { IContextValue, useIContext } from "./common";

type AdminLoginContextType = {
  isAdminLogin?: IContextValue<boolean>;
  adminEmail?: IContextValue<string | null>;
  asRunnerId?: IContextValue<string | null>;
  clear: () => any;
};

export const AdminLoginContext = createContext<AdminLoginContextType>({
  clear: () => {},
});

interface Props {
  children: ReactElement;
}

function AdminLoginContextProvider({ children }: Props) {
  const value: AdminLoginContextType = {
    isAdminLogin: useIContext(!!sessionStorage.getItem(IS_ADMIN_LOGIN)),
    adminEmail: useIContext(sessionStorage.getItem(ADMIN_LOGIN_EMAIL) || null),
    asRunnerId: useIContext(
      sessionStorage.getItem(ADMIN_LOGIN_RUNNER_ID) || null
    ),
    clear: () => {},
  };

  const clear = () => {
    value.adminEmail?.setValue(null);
    value.isAdminLogin?.setValue(false);
    value.asRunnerId?.setValue(null);
    sessionStorage.removeItem(IS_ADMIN_LOGIN);
    sessionStorage.removeItem(ADMIN_LOGIN_EMAIL);
    sessionStorage.removeItem(ADMIN_LOGIN_RUNNER_ID);
    console.log("cleared admin context");
  };

  return (
    <AdminLoginContext.Provider value={{ ...value, clear }}>
      {children}
    </AdminLoginContext.Provider>
  );
}

export default AdminLoginContextProvider;
