import React from "react";
import { Box, Divider } from "@mui/material";
import { ReactElement } from "react";

interface Props {
  leftComponent?: ReactElement;
  rightComponent?: ReactElement;
}
export function Header({ leftComponent, rightComponent }: Props) {
  return (
    <Box
      minHeight={"70px"}
      display="flex"
      sx={{
        boxShadow: "0 6px 6px -6px grey",
      }}
      pt={2}
      pb={2}
    >
      <Box
        width="60%"
        sx={{
          alignItems: "center",
          margin: "auto",
          textAlign: "start",
          pl: "1rem",
        }}
      >
        {leftComponent}
      </Box>
      <Divider
        orientation="vertical"
        sx={{ borderColor: "black", minHeight: "50px", height: "auto" }}
      />
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          display: "block",
          textAlign: "center",
        }}
        width="50%"
      >
        <Box>{rightComponent}</Box>
      </Box>
    </Box>
  );
}
