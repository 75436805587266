import { gql } from "@apollo/client";

export const GET_USER_INFO = gql`
  query getUserInfo {
    getUserInfo {
      username
      groups
      runner {
        _id
        name
        email
        phoneNumber
        runnerNumber
        address
        tfn
        bankAccount
        superAnnuationFund
        venueID
        sectionIDs
        cognitoID
      }
    }
  }
`;

export const GET_RUNNERS = gql`
  query getRunners {
    getRunners {
      _id
      name
      email
      phoneNumber
      runnerNumber
      address
      tfn
      bankAccount
      superAnnuationFund
      venueID
      sectionIDs
      cognitoID
    }
  }
`;

export const GET_RUNNER = gql`
  query getRunner($id: String!) {
    getRunner(id: $id) {
      _id
      name
      email
      phoneNumber
      runnerNumber
      address
      tfn
      bankAccount
      superAnnuationFund
      venueID
      sectionIDs
      cognitoID
    }
  }
`;

export const GET_ORDERS = gql`
  query GetRunnerOrders(
    $runnerID: String!
    $orderFilterOptions: OrderFilterOptions
  ) {
    getRunnerOrders(
      runnerID: $runnerID
      orderFilterOptions: $orderFilterOptions
    ) {
      data {
        serviceMethod
        items {
          _id
          count
          name
          price
          markupPrice
          updatedQuantity
          isRejected
          isUpdated
          customisations {
            name
            _id
            price
            markupPrice
          }
        }
        _id
        runner {
          _id
        }
        patronOrderID
        orderStatus
        isOrderUpdated
        patronOrder {
          location {
            seat
            row
            venue
            section
            venueName
            sectionName
            collectionPoint
            collectionPointName
          }
          orderNumber
          createdAt
          updatedAt
        }
        vendor {
          _id
          tradingName
          stallID
        }
        patron {
          _id
          name
          phoneNumber
        }
        orderNumber
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ITEMS = gql`
  query GetItems($itemQueryParams: ItemQueryParams) {
    getItems(itemQueryParams: $itemQueryParams) {
      _id
      name
      imageUrl
      description
      price
      vendorID
      showInMenu
      outOfStock
    }
  }
`;

export const GET_ALL_NOTIFICATIONS = gql`
  query getAllNotifications($payload: GetAllNotificationsQueryParams!) {
    getAllNotifications(payload: $payload) {
      _id
      subscriberID
      message
      seen
      body
      createdAt
      updatedAt
      type
    }
  }
`;

export const GET_VENUE = gql`
  query getVenue($id: String!) {
    getVenue(id: $id) {
      _id
      name
      venueMapImageUrl
    }
  }
`;
export const GET_RECONCILIATION_REPORT = gql`
  query getRunnerReport(
    $startDate: String
    $endDate: String
    $runnerId: String
  ) {
    getRunnerReport(
      startDate: $startDate
      endDate: $endDate
      runnerId: $runnerId
    ) {
      fileLink
    }
  }
`;
