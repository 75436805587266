import { OrderStatusConfig } from "../types";
import { OrderStatus, ServiceMethod } from "../types/order";

export const getOrderStatusConfig = (serviceMethod: string): OrderStatusConfig => {
  const isCollectionPointOrder = serviceMethod === ServiceMethod.COLLECTION_POINT

 return {
  [OrderStatus.ASSIGNED]: {
    text: "Up Next",
    buttonText: 'Order Picked',
    nextOrderStatus: OrderStatus.PICKED,
    order: 1,
    backgroundColor: "#FFFAEB",
    textColor: "#B54708",
    badgeColor: "#F79009",
  },
  [OrderStatus.PICKED]: {
    text: "Delivering",
    buttonText: isCollectionPointOrder ? 'At Collection Point' : 'Complete',
    nextOrderStatus: isCollectionPointOrder ? OrderStatus.AT_COLLECTION_POINT : OrderStatus.COMPLETE,
    order: 2,
    backgroundColor: "#ECFDF3",
    textColor: "#027A48",
    badgeColor: "#12B76A",
    confirmCheck: isCollectionPointOrder ? false : true
  },
  [OrderStatus.AT_COLLECTION_POINT]: {
    text: "Waiting for Patron",
    buttonText: 'Complete',
    nextOrderStatus: OrderStatus.COMPLETE,
    order: 2,
    backgroundColor: "#ECFDF3",
    textColor: "#027A48",
    badgeColor: "#12B76A",
    confirmCheck: true,
  },
}
}
