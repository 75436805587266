import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ReactComponent as MapIcon } from "../assets/icons/map.svg";
import { IconButton } from "@mui/material";
import { Image } from "../elements/image";
import { Loader } from "../elements/loader";
import { Venue } from "../types";
import { Text } from "../elements/text";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface Props {
  venue: Venue;
  loading: boolean;
}

export default function MapModalIcon(props: Props) {
  const { venue, loading } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      {loading && <Loader visible={true} />}
      <IconButton sx={{ padding: "5px" }} onClick={handleOpen}>
        <MapIcon />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Image src={venue?.venueMapImageUrl} />
        </Box>
      </Modal>
    </Box>
  );
}
