import { useMutation, useQuery } from "@apollo/client";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { HOME_PAGE_ROUTE } from "./constants/routes";
import { AppContext } from "./context/app-context";
import { Loader } from "./elements/loader";
import { fetchToken, onMessageListener } from "./firebase/firebase";
import { GET_RUNNER, GET_USER_INFO } from "./graphql/query";
import { Home } from "./pages/home";
import { Runner, UserType } from "./types/index";
import { PushNotification } from "./components/push-notification";
import { ADD_DEVICE } from "./graphql/mutations";
import { browserName } from "react-device-detect";
import { getOperatingSystem } from "./utils/get-os";
import { Grid } from "@mui/material";
import { Text } from "./elements/text";
import { CustomButton } from "./elements/button";
import { Auth } from "aws-amplify";
import { ReconciliationPage } from "./pages/report";
import { useMutationHook } from "./hooks/use-mutation.hook";
import { AdminLoginContext } from "./context/admin-login-context";
import { useQueryHook } from "./hooks/use-query.hook";

export const Wizard = () => {
  const { runner: runnerInContext } = useContext(AppContext);
  const { user } = useAuthenticator();
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const token = user.getSignInUserSession()?.getAccessToken()?.getJwtToken();
  const [deviceDetailsLoading, setDeviceDetailsLoading] = useState(false);
  const loginContext = useContext(AdminLoginContext);
  const [loading, setLoading] = useState(false);

  const { data, loading: initialLoading } = useQueryHook({
    query: GET_USER_INFO,
    onCompleteHandler: async (data) => {
      const userInfo = data?.getUserInfo;
      const runnerInfo = userInfo?.runner;
      if (!runnerInContext) {
        setLoading(false);
      } else {
        runnerInContext?.setValue(runnerInfo);
      }
    },
  });

  const userInfo = data?.getUserInfo;
  const groups = userInfo?.groups || [];
  const isRunner = groups.includes("runner");
  const isAdmin = groups.includes("admin") || groups.includes("super_admin");

  const { data: runnerData } = useQueryHook({
    query: GET_RUNNER,
    variables: {
      id: loginContext.asRunnerId?.value || runnerInContext?.value?._id,
    },
  });

  const { mutate: updateResource } = useMutationHook({ mutation: ADD_DEVICE });

  const init = async () => {
    try {
      setDeviceDetailsLoading(true);

      const runnerInfo = userInfo?.runner || runnerData?.getRunner;
      if (runnerInfo) {
        runnerInContext?.setValue(runnerInfo);
        await fetchDeviceDetails(runnerInfo);
      }

      setDeviceDetailsLoading(false);
    } catch (e) {
      setDeviceDetailsLoading(false);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      window.location.reload();
    }, 25 * 60000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (userInfo || runnerData) {
      init();
    }
  }, [userInfo, runnerData]);

  const fetchDeviceDetails = async (runner: Runner) => {
    const deviceToken = await fetchToken();
    if (deviceToken && runner) {
      updateResource({
        variables: {
          input: {
            id: runner?._id,
            deviceDetails: {
              browser: browserName,
              os: getOperatingSystem(window),
              token: deviceToken,
            },
            userType: UserType.runner,
          },
        },
      });
    }
  };

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      setShow(true);
    })
    .catch((err) => console.log("failed: ", err));

  if (initialLoading || deviceDetailsLoading || loading) {
    return <Loader visible={true} />;
  }

  if (!(isAdmin || isRunner) || !runnerInContext?.value) {
    return (
      <Grid item container xs={12} sm={6} p={2} rowGap={2}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Text fontWeight={700} fontSize={16} text="You are not a runner" />
        </Grid>
        <Grid item xs={12}>
          <CustomButton title="Signout" onClick={() => Auth.signOut()} />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Routes>
        <Route path={HOME_PAGE_ROUTE} element={<Home />}></Route>
        {/* commented to remove access but keep functionality for future */}
        {/* <Route path={"/report"} element={<ReconciliationPage />}></Route> */}
      </Routes>
    </>
  );
};
