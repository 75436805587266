import React from "react";
import { createContext, ReactElement } from "react";
import { Runner } from "../types";
import { IContextValue, useIContext } from "./common";
import { matchRoutes, useLocation } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { HOME_PAGE_ROUTE } from "../constants/routes";

type AppContextType = {
  runner?: IContextValue<Runner>;
};

export const AppContext = createContext<AppContextType>({});

interface Props {
  children: ReactElement;
}

const routesToInclude = [{ path: HOME_PAGE_ROUTE }];

function AppContextProvider({ children }: Props) {
  const value: AppContextType = {
    runner: useIContext<Runner>(),
  };

  const location = useLocation();
  useIdleTimer({
    timeout: 1 * 60000,
    onIdle: () => {
      console.log("User went idle");
    },
    onActive: () => {
      const match = matchRoutes(routesToInclude, location);
      if (match) {
        window.location.reload();
      }
    },
  });

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export default AppContextProvider;
