import { Typography } from "@mui/material";
import React from "react";
import { customTheme } from "../theme";

interface Props {
  text?: string;
  fontWeight?: number;
  fontSize?: number;
  color?: string;
}

export const Text = ({
  text = "",
  fontWeight,
  fontSize = 14,
  color = "",
}: Props) => {
  return (
    <Typography
      sx={{ wordBreak: "break-word" }}
      fontWeight={fontWeight}
      fontSize={fontSize}
      fontFamily={customTheme.fontFamily}
      color={color}
    >
      {text}
    </Typography>
  );
};
