import { gql } from "@apollo/client";

export const UPDATE_ORDER_STATUS = gql`
  mutation UpdateVendorOrderStatus($id: String!, $status: OrderStatus!) {
    updateVendorOrderStatus(id: $id, status: $status) {
      _id
      orderStatus
    }
  }
`;

export const ADD_DEVICE = gql`
  mutation addDeviceToUser($input: AddDeviceToUserInput!) {
    addDeviceToUser(input: $input)
  }
`;

export const CLEAR_DEVICE = gql`
  mutation clearDeviceFromUser($input: ClearDeviceFromUserInput!) {
    clearDeviceFromUser(input: $input)
  }
`;

export const MARK_AS_SEEN = gql`
  mutation seenNotifications($ids: [String]) {
    seenNotifications(ids: $ids)
  }
`;
